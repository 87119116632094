/* eslint-disable no-unused-expressions */
import { GOOGLE_API_KEY } from "./cred";
import { experienceActions, userActions, bookingActions } from "src/db/dbActions";
import { DataStore } from "@aws-amplify/datastore";
import { Loader } from "@googlemaps/js-api-loader";
import { v4 as uuidv4 } from "uuid";
/**
 * Initialize google maps
 */
export const loader = new Loader({
  apiKey: GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

/**
 * Username validation
 *
 * @param {String} username
 * @returns {Boolean} true / false
 */
export const isValidUsername = (username) => /^[a-z]{1}[a-z0-9-]{2,39}$/.test(username);

/**
 * Zipcode validation
 *
 * @param {String} zip
 * @returns {Boolean} true / false
 */
export const isValidZip = (zip) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

/**
 * Helper function for determining if Scouted Local Experts profile is complete
 *
 * @param {Object} user
 * @returns {Boolean} true / false
 */
export const scoutProfileComplete = (user) => {
  if (
    user.address.aptType && user.address.aptType === "PO BOX"
      ? isFalsy(user.address.aptNumber)
      : isFalsy(user.address.address) ||
        isFalsy(user.address.country) ||
        isFalsy(user.address.state) ||
        isFalsy(user.address.city) ||
        isFalsy(user.firstName) ||
        isFalsy(user.lastName) ||
        isFalsy(user.email) ||
        isFalsy(user.bio) ||
        new RegExp(/[!@#$%^&*()_+={}[\]|\\:";'<>,./?~`]/).test(user.username)
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Helper function for determining if business profile is complete
 *
 * @param {Object} user
 * @returns {Boolean} true / false
 */
export const businessProfileComplete = (user) => {
  if (
    user.address && user.isBusiness && user.address.aptType && user.address.aptType === "PO BOX"
      ? isFalsy(user.address.aptNumber)
      : isFalsy(user.address.address) ||
        isFalsy(user.address.country) ||
        isFalsy(user.address.state) ||
        isFalsy(user.address.city) ||
        isFalsy(user.firstName) ||
        isFalsy(user.lastName) ||
        isFalsy(user.email) ||
        isFalsy(user.businessUrl) ||
        isFalsy(user.businessName) ||
        isFalsy(user.bio) ||
        new RegExp(/[!@#$%^&*()_+={}[\]|\\:";'<>,./?~`]/).test(user.username)
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Helper function for determining if Scouted profile is complete
 *
 * @param {Object} user
 * @returns {Boolean} true / false
 */
export const scoutedProfileComplete = (user) => {
  if (
    user.address && user.address.aptType && user.address.aptType === "PO BOX"
      ? isFalsy(user.address.aptNumber)
      : (user.address &&
          (isFalsy(user.address.address) ||
            isFalsy(user.address.country) ||
            isFalsy(user.address.state) ||
            isFalsy(user.address.city))) ||
        isFalsy(user.firstName) ||
        isFalsy(user.lastName) ||
        isFalsy(user.email)
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Determine if a value is invalid
 *
 * @param {String} val
 * @returns {Boolean} true / false
 */
export const isFalsy = (val) => val === null || val === undefined || val.length === 0;

/**
 * Capitalize first character of a string
 *
 * @param {String} str
 * @returns {String} String with a first letter capitalized
 */
export const capitalize = (str) => {
  return str.replace(str.charAt(0), str.charAt(0).toUpperCase());
};

/**
 * Calculate star rating of an experience
 *
 * @param {Array} bookings
 * @returns {Object} Total count of reviews and the average star rating
 */
export const reviewRating = (bookings) => {
  let rating = 0;
  let count = bookings.length;
  if (bookings.length > 0) {
    bookings.forEach((booking) => {
      rating += booking.review.rating;
    });
    return { count: count, rating: rating / count };
  } else {
    return { count: 0, rating: 0 };
  }
};

/**
 * Check the progress of entered experience details
 *
 * @param {Object} experience
 * @returns {Object} Progress bar value and recommended next item to complete
 */
export const experienceDetailsProgress = (experience, isBusiness = false) => {
  let status = { value: 0, item: "Add a title" };
  if (isBusiness) {
    if (!isFalsy(experience.images)) {
      status = { value: (status.value += 16) };
    }
    if (!isFalsy(experience.itemsProvided)) {
      status = { value: (status.value += 14) };
    }
    if (!isFalsy(experience.itemsToBring)) {
      status = { value: (status.value += 14) };
    }
    if (!isFalsy(experience.businessUrl)) {
      status = { value: (status.value += 14) };
    }
    if (!isFalsy(experience.description) && experience.description.length >= 25) {
      status = { value: (status.value += 14) };
    }
    if (!isFalsy(experience.highlight) && experience.highlight.length >= 15) {
      status = { value: (status.value += 14) };
    }
    if (!isFalsy(experience.title) && experience.title.length >= 5) {
      status = { value: (status.value += 14) };
    }
  } else {
    if (experience.always || !isFalsy(experience.dates)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.images)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.categories)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.itemsProvided)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.itemsToBring)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.price)) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.description) && experience.description.length >= 25) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.highlight) && experience.highlight.length >= 15) {
      status = { value: (status.value += 10) };
    }
    if (!isFalsy(experience.title) && experience.title.length >= 5) {
      status = { value: (status.value += 20) };
    }
  }
  return status;
};

/**
 * Check the progress of a user becomming a scout
 *
 * @param {Object} experience
 * @returns {Object} Progress bar value and recommended next item to complete
 */
export const userRegistrationProgress = (user, config) => {
  let status = { value: 12.5, item: "Add a title" };
  if (config.paymentEnabled && config.checkEnabled) {
    if (user.isScout) {
      status = { value: (status.value += 12.5) };
    }
    if (user.connectAccountVerification) {
      status = { value: (status.value += 12.5) };
    }
    if (config.paymentEnabled && user.registrationFeePaid) {
      status = { value: (status.value += 6.25) };
    }
    if (config.checkEnabled && !isFalsy(user.sterlingClientId)) {
      status = { value: (status.value += 6.25) };
    }

    if (!isFalsy(user.dob)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.username)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.bio)) {
      status = { value: (status.value += 12.5) };
    }
    if (user.isInterested) {
      status = { value: (status.value += 12.5) };
    }
  } else if (config.paymentEnabled && !config.checkEnabled) {
    if (user.isScout) {
      status = { value: (status.value += 12.5) };
    }
    if (user.connectAccountVerification) {
      status = { value: (status.value += 12.5) };
    }
    if (config.paymentEnabled && user.registrationFeePaid) {
      status = { value: (status.value += 12.5) };
    }

    if (!isFalsy(user.dob)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.username)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.bio)) {
      status = { value: (status.value += 12.5) };
    }
    if (user.isInterested) {
      status = { value: (status.value += 12.5) };
    }
  } else if (!config.paymentEnabled && config.checkEnabled) {
    if (user.isScout) {
      status = { value: (status.value += 12.5) };
    }
    if (user.connectAccountVerification) {
      status = { value: (status.value += 12.5) };
    }
    if (config.checkEnabled && !isFalsy(user.sterlingClientId)) {
      status = { value: (status.value += 12.5) };
    }

    if (!isFalsy(user.dob)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.username)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.bio)) {
      status = { value: (status.value += 12.5) };
    }
    if (user.isInterested) {
      status = { value: (status.value += 12.5) };
    }
  } else {
    if (user.isScout) {
      status = { value: (status.value += 18.75) };
    }
    if (user.connectAccountVerification) {
      status = { value: (status.value += 18.75) };
    }


    if (!isFalsy(user.dob)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.username)) {
      status = { value: (status.value += 12.5) };
    }
    if (!isFalsy(user.bio)) {
      status = { value: (status.value += 12.5) };
    }
    if (user.isInterested) {
      status = { value: (status.value += 12.5) };
    }
  }
  return status;
};

/**
 * Check the progress of entered experience details
 *
 * @param {Object} experience
 * @returns {Object} Progress bar value and recommended next item to complete
 */
export const businessExperienceDetailsProgress = (experience) => {
  let status = { value: 0, item: "Add a title" };
  if (!isFalsy(experience.images)) {
    status = { value: (status.value += 16) };
  }
  if (!isFalsy(experience.itemsProvided)) {
    status = { value: (status.value += 14) };
  }
  if (!isFalsy(experience.itemsToBring)) {
    status = { value: (status.value += 14) };
  }
  if (!isFalsy(experience.businessUrl)) {
    status = { value: (status.value += 14) };
  }
  if (!isFalsy(experience.description) && experience.description.length >= 25) {
    status = { value: (status.value += 14) };
  }
  if (!isFalsy(experience.highlight) && experience.highlight.length >= 15) {
    status = { value: (status.value += 14) };
  }
  if (!isFalsy(experience.title) && experience.title.length >= 5) {
    status = { value: (status.value += 14) };
  }

  return status;
};

/**
 * Initialize and create cloudinary image upload window
 *
 * @param {Object} data
 * @param {String} path
 * @param {String} preset
 * @param {Boolean} isReview
 * @returns Cloudinary upload widget
 */
export const cloudinaryWidget = (data, path, preset, isReview = false) => {
  let tempImages = [];

  if (!isReview && preset === "experiences" && data.images && data.images.length > 0) {
    tempImages = data.images;
  }
  return window.cloudinary.createUploadWidget(
    {
      cloudName: "dam4j2o9t",
      sources: ["local", "camera", "facebook", "instagram", "shutterstock", "google_drive"],
      uploadPreset: preset,
      cropping: preset === "avatar" ? true : false,
      multiple: preset === "avatar" || isReview ? false : true,
      maxFiles: preset === "avatar" || isReview ? 1 : 20,
      maxImageWidth: preset === "avatar" ? 150 : 1024,
      maxImageHeight: preset === "avatar" ? 150 : 1024,
      minImageWidth: preset === "avatar" ? 150 : 400,
      minImageHeight: preset === "avatar" ? 150 : 400,
      defaultSource: "local",
      resourceType: "image",
      folder:
        process.env.NODE_ENV === "production" && window.location.origin === "https://www.scouted.com"
          ? `production/${path}`
          : `development/${path}`,
      styles: {
        palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#0078FF",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#0078FF",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#E4EBF1",
        },
        fonts: {
          default: {
            active: true,
          },
        },
      },
    },
    (error, result) => {
      if (!isReview && !error && result && result.event === "queues-end" && preset === "experiences") {
        experienceActions.updateExperience(
          Object.assign({}, data, {
            images: tempImages.length <= 10 ? tempImages : tempImages.slice(0, 10),
          })
        );
      }
      if (!error && result && result.event === "success") {
        if (isReview) {
          bookingActions.updateBooking(
            Object.assign({}, data, {
              review: Object.assign({}, data.review, {
                image: result.info.secure_url,
              }),
            })
          );
        } else if (preset === "experiences") {
          tempImages = [result.info.secure_url, ...tempImages.filter((img) => img !== result.info.secure_url)];
        } else if (preset === "avatar") {
          userActions.updateUser(
            Object.assign({}, data, {
              avatar: result.info.secure_url,
            })
          );
        }
      }
    }
  );
};

/**
 * Force update by purging all data from cache and datastore
 */
export const purgeData = async () => {
  localStorage.removeItem("filters");
  localStorage.removeItem("manual");
  localStorage.removeItem("multiple");
  try {
    await DataStore.clear();
  } catch (error) {
    console.error(error);
    DataStore.start();
  }
};

/**
 * Cleans input in the event of an attempt for xss
 *
 * @param {String} val
 * @returns Cleaned value
 */
export const cleanInput = (val) => {
  return val.replace(/<(?!br).*?>/g, "");
};

/**
 * Calculate costs for experience purchase
 *
 * @returns amount onject with totals
 */
export const purchaseCalculations = (applicationPercent, currentCoupon, experience, selectedParticipants, user) => {
  const { price } = experience;
  let cost = parseFloat(price) * selectedParticipants;
  let discount = 0;
  let total = 0;
  let fee = 0;
  let affiliateAmount = 0;
  if (currentCoupon && currentCoupon.percentOff) {
    discount = currentCoupon.percentOff / 100;
    total = cost - cost * discount;
    fee = total * applicationPercent;
    affiliateAmount = user && user.affiliateId ? total * 0.01 : 0;
  } else if (currentCoupon && currentCoupon.amountOff) {
    discount = currentCoupon.amountOff;
    total = cost - discount;
    fee = total * applicationPercent;
    affiliateAmount = user && user.affiliateId ? total * 0.01 : 0;
  } else {
    total = cost - discount;
    fee = total * applicationPercent;
    affiliateAmount = user && user.affiliateId ? total * 0.01 : 0;
  }
  return { discount, total, fee, affiliateAmount };
};

/**
 * Confirm unique date ids
 */
export const confirmDateIds = (dates) => {
  let newDates;
  dates.map((date) =>
    !date.id && date.original
      ? { ...date, id: `${date.expId}-${uuidv4()}`, original: { ...date.original, id: `${date.expId}-${uuidv4()}` } }
      : {}
  );

  return newDates;
};
