import React from "react";
import Layout from "../components/layout/Layout";
import ExperienceCard from "../components/cards/ExperienceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InputGroup from "react-bootstrap/InputGroup";
import MetaTags from "src/components/layout/MetaTags";
import { Alert } from "react-bootstrap";
// import { experienceActions, userActions, affiliateActions } from "src/db/dbActions";
import { experienceActions, userActions } from "src/db/dbActions";
import { locationService, authService } from "src/api/apiActions";
import CustomSlider from "src/components/CustomSlider";
import CategoryCard from "src/components/cards/CategoryCard";
import { CATEGORIES, LANDING_HERO, TWITTER_LANDING, STATES } from "src/constants";
import { Datepicker } from "@mobiscroll/react";
import { loader } from "src/utils";
import { Hub } from "@aws-amplify/core";
import "./LandingPage.css";
// import { infoExperiences } from "../backupdata/experiences.js";

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      filters: {
        search: "",
        startDate: null,
        endDate: null,
      },
      featuredExperiences: [],
      hasLocationError: false,
      user: null,
      dbReady: false,
      noLocation: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const currentUser = {}

    console.log("DID MOUNT");
    // userActions.createNewUser(currentUser).then((user) => {
    //   console.log("User was created: ", user);
    //   if (user.affiliateId) {
    //     console.log("User is an affiliate");
    //     affiliateActions.newAffiliate({ userId: user.id, code: user.username }).then((affiliate) => {
    //       console.log("Created new affiliate", affiliate);
    //       userActions
    //         .updateUser(Object.assign({}, user, { isReferer: true, affiliateId: affiliate.id }))
    //         .then((updated) => console.log("Successfully updated user: ", updated));
    //     });
    //   }
    //   infoExperiences.map((exp, index) =>
    //     experienceActions
    //       .createNewExperience({ ...exp, scout: user, scoutId: user.id })
    //       .then((res) => console.log("Completed ", exp.id, exp.title, index, res))
    //   );
    // });

    locationService.getCurrentLocation().catch((e) => this.setState({ noLocation: true }));
    authService
      .isAuthenticated()
      .then((cognito) => {
        userActions.getCurrentUser({ email: cognito.attributes.email }).then((user) => {
          // console.log(infoExperiences)
          // infoExperiences.map(
          //   (exp, index) =>
          //     experienceActions
          //       .createNewExperience({ ...exp, scout: user[0], scoutId: user[0].id })
          //       .then((res) => console.log("Completed ", exp.title, index))
          // );
          this.setState({
            user: user[0],
            filters: JSON.parse(localStorage.getItem("filters")),
          });
        });
      })
      .catch((e) =>
        this.setState({
          filters: JSON.parse(localStorage.getItem("filters")),
        })
      );
    Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        experienceActions.getAllExperiences().then((exps) => {
          this.setState(
            {
              experiences: exps.filter((exp) => exp.active),
              featuredExperiences: exps.filter((exp) => exp.active && exp.isFeatured),
              filters: JSON.parse(localStorage.getItem("filters")),
            },
            () => this.renderCitySelection()
          );
        });
      }
    });
  }

  /**
   * Display state selection with count of experiences in that state
   *
   * @returns Selection element
   */
  renderCitySelection() {
    const { filters, experiences } = this.state;
    return (
      <Form.Select
        className="search-form-control "
        style={{ padding: "0.75rem", width: "100%" }}
        aria-label="Select State"
        onChange={(e) => {
          this.setState(
            {
              filters: Object.assign({}, filters, { search: e.target.value }),
            },
            () =>
              localStorage.setItem("filters", JSON.stringify(Object.assign({}, filters, { search: e.target.value })))
          );
        }}
      >
        <option>Select State</option>
        {STATES.map((state, index) => {
          let count =
            experiences &&
            experiences.filter((exp) => {
              return (
                exp.active &&
                exp.location &&
                exp.location !== "" &&
                exp.location.split(",").length === 3 &&
                exp.location.split(",")[exp.location.split(",").length - 2].includes(state.short)
              );
            }).length;
          return (
            <option key={index} value={state.short}>
              {`${state.long} ${count > 0 ? `( ${count} )` : ""}`}
            </option>
          );
        })}
      </Form.Select>
    );
  }

  render() {
    const { featuredExperiences, user, filters } = this.state;
    return (
      <Layout>
        <MetaTags
          title="Scouted - Book Local US Experiences | Things to do"
          description="Book Local US Experiences from Scouts using the Scouted Platform! Looking for something authentic to do on your next trip? Scouted has US Things to do!"
          url={`${window.location.origin}`}
          image={TWITTER_LANDING}
        />
        <div
          className="text-md-start px-md-5 py-5 index-container"
          id="LandingPage"
          style={{ backgroundImage: `url(${LANDING_HERO})` }}
        >
        
          {/* <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          > */}
          {/* <Row className="justify-content-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3"> */}

          {/* <Tabs defaultActiveKey="search" className="landing-tabs">*/}
            {/* OPEN TAB FOR SEARCH */}

            {/*<Tab eventKey="search" className="landing-tab" title="Search" id="search_tab"></Tab>*/}
              <Col sm={12} className="just-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3">
                <Form.Label className="text-white">
                  <h1 className="landing-h1">Your next great experience awaits you...</h1>
                  <h2 className="landing-h2">
                    Choose your location, grab some dates, you're on your way to amazing experiences
                  </h2>
                </Form.Label>
              </Col>

              <Col xs={12} sm={12} className="justify-content-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3">
                <Row className="pt-2 pe-0 px-2 px-sm-2 px-md-0 pb-2 pb-sm-2 pb-md-0 ps-2 ps-sm-2 ps-md-2">
                  <InputGroup className="">
                    <Col xs={12} sm={12} md={3} lg={3} className="m-0 p-0 pe-1 mb-2">
                      {this.renderCitySelection()}
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className="m-0 p-0 pe-1 mb-2">
                      <div id="height-fix">
                        <Datepicker
                          className="is-valid search-form-control height-control"
                          maxWidth={1200}
                          select="range"
                          inputProps={{
                            placeholder: "Select Dates",
                            style: {
                              border: "#28a745 2px solid",
                              backgroundColor: "white",
                              fontFamily: "inherit",
                              transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                              padding: "1rem",
                              color: "black",
                              fontSize: "1rem",
                              fontWeight: "400",
                              lineHeight: "1.5",
                              width: "100%",
                            },
                          }}
                          responsive={{
                            small: {
                              display: "center",
                              layout: "liquid",
                            },
                            medium: {
                              width: 600,
                              display: "center",
                              layout: "liquid",
                              pages: 2,
                            },
                            large: {
                              width: 800,
                              display: "center",
                              layout: "liquid",
                              pages: 2,
                            },
                            xlarge: {
                              width: 1000,
                              display: "center",
                              layout: "liquid",
                              pages: 3,
                            },
                          }}
                          themeVariant="light"
                          theme="material"
                          onChange={(e) => {
                            if (!e.value.includes(null)) {
                              localStorage.setItem(
                                "filters",
                                JSON.stringify(
                                  Object.assign({}, filters, { startDate: e.value[0], endDate: e.value[1] })
                                )
                              );
                              this.setState({
                                filters: Object.assign({}, filters, { startDate: e.value[0], endDate: e.value[1] }),
                              });
                            }
                          }}
                          controls={["calendar"]}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="m-0 p-0 pe-1 mb-2">
                      <Form.Control
                        className="search-form-control"
                        style={{ padding: "0.75rem", width: "100%" }}
                        placeholder="Search Keywords"
                        onChange={(e) => {
                          this.setState(
                            {
                              filters: Object.assign({}, filters, { keyWord: e.target.value }),
                            },
                            () =>
                              localStorage.setItem(
                                "filters",
                                JSON.stringify(Object.assign({}, filters, { keyWord: e.target.value }))
                              )
                          );
                        }}
                      />
                    </Col>
                    <Col xs={12} sm={4} md={2} lg={2} className="m-0 p-0 float-end pb-5">
                      <Button
                        id="search_btn"
                        onClick={() => {
                          this.props.history.push({
                            pathname: filters && filters.search ? `/search/${filters.search.toLowerCase()}` : "/search",
                          });
                        }}
                        className="search-icon"
                        style={{ borderColor: "white", borderSize: "2px", borderStyle: "solid" }}
                      >
                        <FontAwesomeIcon id="search_btn" color="white" icon="search" alt="Search" />
                      </Button>
                      <Button
                        id="c_hairs"
                        className="location-icon"
                        style={{ borderColor: "white", borderSize: "2px", borderStyle: "solid" }}
                        onClick={() => {
                          loader.load().then(() => {
                            locationService
                              .getCurrentLocation()
                              .then((loc) => {
                                let geo = new window.google.maps.Geocoder();
                                geo
                                  .geocode({ location: { lat: loc.coords.latitude, lng: loc.coords.longitude } })
                                  .then((res) => {
                                    localStorage.setItem(
                                      "filters",
                                      JSON.stringify(
                                        Object.assign({}, filters, {
                                          gpsPos: [loc.coords.latitude, loc.coords.longitude],
                                          search: res.results[0].address_components
                                            // eslint-disable-next-line
                                            .map((i) => {
                                              if (i.types.includes("locality")) {
                                                return i.long_name;
                                              } else if (i.types.includes("administrative_area_level_1")) {
                                                return i.short_name;
                                              } else if (i.types.includes("country")) {
                                                return i.short_name;
                                              }
                                            })
                                            .filter((i) => i !== undefined)
                                            .join(", "),
                                        })
                                      )
                                    );
                                    this.props.history.push({
                                      pathname: "/search",
                                    });
                                  });
                              })
                              .catch((e) =>
                                this.props.history.push({
                                  pathname: "/search",
                                })
                              );
                          });
                        }}
                      >
                        <FontAwesomeIcon id="c_hairs" color="white" icon={"crosshairs"} alt="Location" />
                      </Button>
                    </Col>
                  </InputGroup>
                </Row>
              </Col>
              {/* </Row> */}
              {/* </Form> */}
           {/* </Tab> */}

            {/* HIDE OPEN TAB FOR SCOUTS 

            <Tab eventKey="scouts" title="Scouts" id="scouts_tab">
              <Col sm={12} className="just-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3">
                <Form.Label className="text-white">
                  <h1 className="landing-h1">
                    Experience making money doing what you love,
                    <br /> Become a Scout!
                  </h1>
                  <p className="py-3">
                    <Link to="/be-a-scout/purchase-options">
                      <Button id="s_index_sup" className="scouted-btn scouted-btn-cta scouted-btn-sep icon-register">
                        Sign Up!
                      </Button>
                    </Link>
                  </p>
                  <p>
                    <Link to="/be-a-scout/how-it-works" className="text-white">
                      <u>Learn more about becoming a Scout</u>
                    </Link>
                  </p>
                </Form.Label>
              </Col>
            </Tab>

            */}

            {/* HIDE OPEN TAB FOR BUSINESSES 

            <Tab eventKey="businesses" title="Business" id="bus_tab">
              {" "}
              <Col sm={12} className="just-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3">
                <Form.Label className="text-white">
                  <h1 className="landing-h1">
                    Become a Scouted Business partner and <br />
                    Get more traffic for your business!
                  </h1>
                  <p className="py-3">
                    <Link to="/business/purchase-options">
                      <Button id="b_index_sup" className="scouted-btn scouted-btn-cta scouted-btn-sep icon-cart">
                        Buy Now!
                      </Button>
                    </Link>
                  </p>
                  <p>
                    <Link to="/business/partnership-program" className="text-white">
                      <u>Learn more about becoming a Business partner</u>
                    </Link>
                  </p>
                </Form.Label>
              </Col>
            </Tab>
            */}
            {/* HIDE OPEN TAB FOR AFFILIATES 

            <Tab eventKey="affiliates" title="Affiliates" id="aff_tab">
              {" "}
              <Col sm={12} className="just-center pt-lg-5 pt-md-4 pt-sm-1 px-3 px-sm-3">
                <Form.Label className="text-white">
                  <h1 className="landing-h1">Scouts can get great perks with our Affiliate Program!</h1>
                  <p className="py-3">
                    <Link to="/contact-scouted">
                      <Button id="a_index_sup" className="scouted-btn scouted-btn-cta scouted-btn-sep icon-register">
                        Contact Us!
                      </Button>
                    </Link>
                  </p>
                  <p>
                    <Link to="/be-a-scout/affiliate-program" className="text-white">
                      <u>Learn more about joining the affiliate program</u>
                    </Link>
                  </p>
                </Form.Label>
              </Col>
            </Tab>

            */}
        {/*  </Tabs>*/}
        </div>

        <div className="justify-content-center mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-3">
          <div className="white-bg-solid negative-margin pt-2">
            <Row className="text-center px-4 pt-3 pb-2">
              <Col sm={12} className="text-start">
                <span className="landing-title">Find Experiences that suit your style</span>
              </Col>
            </Row>
            <div className="px-4" id="categories">
              <CustomSlider>
                {CATEGORIES.sort((a, b) => b.count - a.count).map((category, index) => {
                  return <CategoryCard {...category} key={index} {...this.props} />;
                })}
              </CustomSlider>
            </div>
          </div>
        </div>
        {featuredExperiences && featuredExperiences.length > 0 && (
          <div className="justify-content-center mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-3">
            <div className="pt-2">
              <Row className="text-center px-4 pt-3 pb-2">
                <Col sm={12} className="text-start">
                  <span className="landing-title">A Selection of Featured Experiences</span>
                </Col>
              </Row>
              <div className="px-4 ">
                <CustomSlider>
                  {featuredExperiences.map((experience, index) => {
                    return <ExperienceCard key={index} experience={experience} user={user} index={index} />;
                  })}
                </CustomSlider>
              </div>
            </div>
          </div>
        )}

        <Container id="LandingPage" className="py-5">
          <Row className="container-wide">
            <Col sm={12} md={6} lg={6}>
              <h2>Looking to Book Authentic Experiences?</h2>
              <p>
                Scouted is the first activity-based platform connecting local experts or Scouts to travelers looking for
                the best authentic experiences.
              </p>
              <p>
                Want to get started? Simply use the search bar above to start looking for your next adventure or click a
                category that interests you and start exploring!
              </p>
              <p>
                <Link to={"/search"}>Book a Scout!</Link>
              </p>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <h2>Want to Become a Scout?</h2>
              <p>
                Love where you live? Know a lot about something you love? Feel like making money doing something that
                you actually enjoy?
              </p>
              <p>
                <Link to={"/be-a-scout/purchase-options"}>Become a Scout!</Link>
              </p>

              <p>
                With Scouted, the possibilities are endless. All you have to do is{" "}
                <Link to={"/be-a-scout/purchase-options"}>sign up for a Scouted account</Link>, upload your experience,
                wait for it to be booked and get paid. It’s that easy.
              </p>

              <p>
                Check out our <Link to={"/how-scouted-works"}>How Scouted Works</Link> page for more info or{" "}
                <Link to={"/be-a-scout/purchase-options"}>sign up for a Scouted account now</Link> and start making
                money tomorrow!
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default LandingPage;
