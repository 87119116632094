import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import { Amplify } from "@aws-amplify/core";
import API from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import config from "./aws-exports";
import { CURRENT_VERSION } from "./cred";
import { purgeData } from "./utils";
import { authService } from "./api/apiActions";
import { DataStore } from "@aws-amplify/datastore";
import "./index.css";

// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
// );
Amplify.configure({
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
  },
});
Amplify.register(Auth);
Amplify.register(API);
DataStore.start();

localStorage.removeItem("registration");

/**
 * Log user out of every window, refresh the data, and redirect to landing page
 */
const storageChange = (event) => {
  let status = localStorage.getItem("logged_in");
  let id = localStorage.getItem("logged_in_id");
  if (event.key === "logged_in" && !status) {
    purgeData().finally(() => window.location = "/");
  } else if (event.key === "logged_in" && status) {
    window.location = `/profile/${id}`;
  }
  let installed_version = localStorage.getItem("scouted-installed-version") || "";
  if (CURRENT_VERSION !== installed_version) {
    purgeData()
        .finally(() => authService
            .isAuthenticated()
            .then(() => authService.signOut())
            .catch((e) => e));
  }
  localStorage.setItem("scouted-installed-version", CURRENT_VERSION);
};

/**
 * Check the version a user is running if it is out of date force refresh
 */
let installed_version = localStorage.getItem("scouted-installed-version") || "";
if (CURRENT_VERSION !== installed_version) {
  localStorage.setItem("scouted-installed-version", CURRENT_VERSION);
  purgeData()
    .finally(() => authService
        .isAuthenticated()
        .then(() => authService.signOut())
        .catch((e) => e));
}
window.addEventListener("storage", storageChange, false);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
